/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, firstValueFrom, lastValueFrom, map, shareReplay, tap } from 'rxjs';
import { AccountApi } from '../api/auth/account-api';
import { UserRole, UserToken } from '../generated/model/auth';
import { Role } from '../enums/Role';
import { LogService } from './log.service';
import { CryptoHelper, TokenStorage } from '../helpers';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends TokenStorage {
  private _initialized$: Subject<boolean>;
  private _error$: Subject<any>;
  private _userRole: UserRole | undefined;
  private initialized: boolean = false;
  private loading: boolean = false;
  private console = LogService.initialize('UserService');

  constructor(private accountApi: AccountApi, cryptoHelper: CryptoHelper) {
    super(cryptoHelper);
    this.console.log(`constructor()`);
    this._initialized$ = new ReplaySubject<boolean>(1);
    this._error$ = new ReplaySubject<any>(1);
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get error$(): Subject<any> {
    return this._error$;
  }

  public get user(): UserRole | undefined {
    return this._userRole;
  }

  public initialize(): void {

    if (!this.initialized && !this.loading) {
      this.loading = true;

      this.accountApi.getUser().subscribe({
        next: user => {
          if (user) {
            this._userRole = user;
            this.updateToken(user);
            this.loading = false;
            this.initialized = true;
            this.console.log(`INITIALIZED`);
            this._initialized$.next(true);
            this._initialized$.complete();
          } else {
            console.error(`getUser return null`);
            this._error$.next(new HttpErrorResponse({}));
            this.loading = false;
          }

        },
        error: (e) => {
          console.error(`getUser failed`, e);
          this._error$.next(e);
          this.loading = false;
        },
        complete: () => this.console.log(`getUser complete`)
      });
    }
  }

  public update(): Observable<UserRole> {
    return this.accountApi.getUser().pipe(tap(
      user => {
        this._userRole = user;
        this.updateToken(user);
      })).pipe(shareReplay());
  }

  public hasAnyRoleAsync(role: Role): Promise<boolean> {
    return lastValueFrom(this.initialized$.pipe(map(_ => this.hasAnyRole(role))));
  }

  public hasRole(role: Role): boolean {
    const userRole: number = this._userRole?.role as number;
    return (userRole & role) === role;
  }

  public hasAnyRole(role: Role): boolean {
    const userRole: number = this._userRole?.role as number;
    return (userRole & role) !== 0;
  }

  public hasOnlyRole(role: Role): boolean {
    const userRole: number = this._userRole?.role as number;
    return (userRole === role);
  }

  private updateToken(user: UserRole): void {
    let modified: boolean = true;
    const userToken: UserToken | null = this.getUser();

    if (userToken) {
      if (user.uuid !== userToken.uuid) {
        this.clearUser();
        modified = true;
      } else {
        modified = false;
        if (userToken.displayName !== user.displayName) {
          userToken.displayName = user.displayName;
          modified = true;
        }

        if (userToken.login !== user.login) {
          userToken.login = user.login;
          modified = true;
        }
      }
    }

    if (modified) {
      this.saveUser(userToken as UserToken);
    }
  }
}
