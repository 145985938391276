/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { DashboardStringService } from './services/dashboard/string.service';
import { B2BStringService } from './services/b2b/string.service';
import { BioStringService } from './services/bio/string.service';

// https://angular.io/guide/router

const routes: Routes = [
  {path: 'b2b/firm', loadChildren: () => import('./modules/simulation/b2b/simulation.module').then(m => m.B2BSimulationModule), canActivate: [ AuthGuard ], resolve: { any: B2BStringService}},
  {path: 'bio/firm', loadChildren: () => import('./modules/simulation/bio/simulation.module').then(m => m.BioSimulationModule), canActivate: [ AuthGuard ], resolve: { any: BioStringService}},
  {path: 'b2b/debriefer', loadChildren: () => import('./modules/debriefer/b2b/debriefer.module').then(m => m.B2BDebrieferModule), canActivate: [ AuthGuard ], resolve: { any: B2BStringService}},
  {path: 'bio/debriefer', loadChildren: () => import('./modules/debriefer/bio/debriefer.module').then(m => m.BioDebrieferModule), canActivate: [ AuthGuard ], resolve: { any: BioStringService}},
  {path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [ AuthGuard ], resolve: { any: DashboardStringService}},
  {path: 'portal', loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule), canActivate: [ AuthGuard ]},
  {path: 'state', loadChildren: () => import('./modules/state/state.module').then(m => m.StateModule), canActivate: [ AuthGuard ]},
  {path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
  {path: 'tools/sat', loadChildren: () => import('./modules/tools/sat/sat.module').then(m => m.SatModule), canActivate: [ AuthGuard ]},
  {path: 'tools/star', loadChildren: () => import('./modules/tools/star/star.module').then(m => m.StarModule), canActivate: [ AuthGuard ]},
  {path: 'tools/qswot', loadChildren: () => import('./modules/tools/qswot/qswot.module').then(m => m.QswotModule), canActivate: [ AuthGuard ]},
  {path: 'tools/chess', loadChildren: () => import('./modules/tools/chess/chess.module').then(m => m.ChessModule), canActivate: [ AuthGuard ]},
  {
    path: '**',
    redirectTo: 'portal',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
