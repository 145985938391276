import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StringKeyDashboardStringValue } from 'src/app/generated/model/scenario';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DashboardStringApi {
  private readonly bn: string;

  constructor(
    private http: HttpClient) {
    this.bn = environment.formatBuildNumber(environment.buildNumber);
  }

  public getStringValues(): Observable<StringKeyDashboardStringValue[]> {
    return this.http.get<StringKeyDashboardStringValue[]>(`./strings/Dashboard-StringValue.json?i=${this.bn}`);
  }
}
