/**
 * ScenarioApi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by CentrX/CentrXBackend/InterfaceGenerator/ApiGenerator.
 * Do not edit the class manually.
 */
/* eslint-disable max-len */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services';
import { ScenarioDescription } from '../../model/scenario';

@Injectable({
  providedIn: 'root'
})
export class ScenarioApi {
  private defaultHeaders = new HttpHeaders();
  private baseUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
    const { scenarioBaseUrl } = this.configService.config;
    this.baseUrl = scenarioBaseUrl;
  }

  protected getScenariosDescriptionsScenarioDescriptions(headers: HttpHeaders | null = null): Observable<ScenarioDescription[]> {
    const url = `${this.baseUrl}/api/Scenario/scenariosDescriptions`;
    return this.http.get<ScenarioDescription[]>(url, {headers: headers ?? this.defaultHeaders});
  }
}
