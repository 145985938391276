/**
 * AuthApi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by CentrX/CentrXBackend/InterfaceGenerator/ApiGenerator.
 * Do not edit the class manually.
 */
/* eslint-disable max-len */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services';
import { UserToken } from '../../model/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthApi {
  private defaultHeaders = new HttpHeaders();
  private baseUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
    const { authBaseUrl } = this.configService.config;
    this.baseUrl = authBaseUrl;
  }

  protected postLoginUserToken(
    loginId: string,
    headers: HttpHeaders | null = null
  ): Observable<UserToken> {

    if (!loginId) {
      throw new Error('Required parameter loginId was null or undefined when calling AuthApi.postLoginUserToken.');
    }
    const url = `${this.baseUrl}/api/Auth/login`;
    return this.http.post<UserToken>(url, `"${loginId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
     });
  }

  protected postUtcNow(headers: HttpHeaders | null = null): Observable<string> {
    const url = `${this.baseUrl}/api/Auth/utcNow`;
    return this.http.post<string>(url, {}, {headers: headers ?? this.defaultHeaders});
  }
}
