/**
 * CentrXSeminar.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type FirmId = 'None' | 'Firm_C' | 'Firm_E' | 'Firm_N' | 'Firm_T' | 'Firm_R' | 'Firm_X' | 'Firm_1' | 'Firm_2' | 'Firm_3' | 'Firm_4' | 'Firm_5' | 'Firm_6' | 'Firm_7';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FirmId = {
    None: 'None' as FirmId,
    FirmC: 'Firm_C' as FirmId,
    FirmE: 'Firm_E' as FirmId,
    FirmN: 'Firm_N' as FirmId,
    FirmT: 'Firm_T' as FirmId,
    FirmR: 'Firm_R' as FirmId,
    FirmX: 'Firm_X' as FirmId,
    Firm1: 'Firm_1' as FirmId,
    Firm2: 'Firm_2' as FirmId,
    Firm3: 'Firm_3' as FirmId,
    Firm4: 'Firm_4' as FirmId,
    Firm5: 'Firm_5' as FirmId,
    Firm6: 'Firm_6' as FirmId,
    Firm7: 'Firm_7' as FirmId
};