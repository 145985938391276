import { Injectable } from '@angular/core';
import { DictionaryOf } from '../../model';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { StringKeyDashboardStringValue } from 'src/app/generated/model/scenario';
import { DashboardStringApi } from '../../api/string/dashboard-string-api';
import { ActivatedRouteSnapshot, MaybeAsync, Resolve, RouterStateSnapshot } from '@angular/router';
import { IStringService } from 'src/app/interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardStringService implements IStringService, Resolve<boolean> {
  private _initialized$: Subject<boolean>;
  private _strings: DictionaryOf<string> = {};
  private _stringValues: StringKeyDashboardStringValue[] = [];

  constructor(private stringApi: DashboardStringApi) {
    console.log(`DashboardStringService.constructor()`);
    this._initialized$ = new ReplaySubject<boolean>(1);

    combineLatest([
      this.stringApi.getStringValues()]).subscribe(([stringValues]) => {
        this._stringValues = stringValues;

        this._stringValues.forEach(s => {
          if (s && s.name && s.value) {
            this._strings[s.name] = s.value;
          }
        });

        console.log(`DashboardStringService INITIALIZED`);
        this._initialized$.next(true);
      });
  }


  public get strings(): DictionaryOf<string> {
    return this._strings;
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<boolean> {
    return this._initialized$;
  }
}
