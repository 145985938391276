import { Company } from "src/environments/company";

declare global {
  interface String {
    productName(): string;
    projectName(): string;
    capitalize(): string;
    toZString(): string;
    replaceCompanyAndBrandName(): string | undefined;
  }
}

String.prototype.productName = function (): string {
  const index = this.indexOf('_');
  return index !== -1 ? this.substring(0, index) : this as string;
};

String.prototype.projectName = function (): string {
  const index = this.indexOf('_');
  return index !== -1 ? this.substring(0, index) : this as string;
};

String.prototype.capitalize = function (): string {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.toZString = function (): string {
  const zdate = this.replace(/Z$/, '') + 'Z';
  return zdate;
};

String.prototype.replaceCompanyAndBrandName = function (): string | undefined {
  return this
    .replaceAll('{CompanyName}', Company.COMPANYNAME)
    .replaceAll('{BrandName}', Company.BRANDNAME);
}
