import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Market } from 'src/app/generated/model/decision/market';

@Injectable({
  providedIn: 'root'
})
export class MarketService {

  private _selectedMarket: Market;
  private _selectedMarket$!: Subject<Market>;
  private _selectedMarketIndex$!: Subject<number>;

  constructor() {

    this._selectedMarket = Market.FirstMarket;
    this._selectedMarket$ = new BehaviorSubject(Market.FirstMarket);
    this._selectedMarketIndex$ = new BehaviorSubject(0);
  }

  public get selectedMarketIndex(): number {
    return this.getMarketIndex(this._selectedMarket);
  }

  public get selectedMarket$(): Subject<Market> {
    return this._selectedMarket$;
  }

  public get selectedMarketIndex$(): Subject<number> {
    return this._selectedMarketIndex$;
  }

  public get selectedMarket(): Market {
    return this._selectedMarket;
  }

  public set selectedMarket(newMarket: Market) {
    this._selectedMarket = newMarket;
    this._selectedMarket$.next(newMarket);
    this._selectedMarketIndex$.next(this.getMarketIndex(newMarket));
  }

  public getMarketIndex(market: Market): number {
    return (market === Market.FirstMarket) ? 0 : 1 ;
  }

}
