import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateTimeTools {
  public static formatUTCDate(utcDate: Date): any {

    if (utcDate) {
      const date = new Date(utcDate.toString().toZString());

      const result =  (
        [
          date.getFullYear(),
          DateTimeTools.padTo2Digits(date.getMonth() + 1),
          DateTimeTools.padTo2Digits(date.getDate()),
        ].join('-') +
        'T' +
        [
          DateTimeTools.padTo2Digits(date.getHours()),
          DateTimeTools.padTo2Digits(date.getMinutes())
          //this.padTo2Digits(date.getSeconds()),
        ].join(':')
      );

      return result;
    }

    return undefined;

  }

  public static formatDate(localDate: Date): any {
    const date = new Date(localDate);

    const result =  (
      [
        date.getFullYear(),
        DateTimeTools.padTo2Digits(date.getMonth() + 1),
        DateTimeTools.padTo2Digits(date.getDate()),
      ].join('-') +
      'T' +
      [
        DateTimeTools.padTo2Digits(date.getHours()),
        DateTimeTools.padTo2Digits(date.getMinutes())
        //this.padTo2Digits(date.getSeconds()),
      ].join(':')
    );

    return result;
  }

  public static getTimeZone(): string {
    const offset = new Date().getTimezoneOffset();
    const hoursOffset = Math.ceil(offset/60);
    const minutesOffset = offset%60;
    const timeOption = Intl.DateTimeFormat().resolvedOptions();

    let timeOffset = (hoursOffset)?  (hoursOffset>0) ? `UTC${hoursOffset*-1}`: `UTC+${hoursOffset*-1}` :'UTC';

    if (minutesOffset) {
      timeOffset += `:${minutesOffset*-1}`;
    }

    let timeZone = timeOption.timeZone;
    if (timeZone) {
      timeZone = timeZone.replaceAll('_',' ');
    }

    return `${timeZone} Time (${timeOffset})`;
  }

  private static padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
