import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
/* eslint-disable-next-line max-len */
import { InitiativePrefixesB2BInitiativeKeyB2BInitiative, InsightB2B, MailKeyB2BMail, MenuEntryKeyB2BMenuEntry, StringKeyB2BStringValue } from 'src/app/generated/model/scenario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class B2BStringApi {
  private readonly bn: string;

  constructor(
    private http: HttpClient) {
    this.bn = environment.formatBuildNumber(environment.buildNumber);
  }

  public getInitiatives(): Observable<InitiativePrefixesB2BInitiativeKeyB2BInitiative[]> {
    return this.http.get<InitiativePrefixesB2BInitiativeKeyB2BInitiative[]>(`./strings/B2B-Initiative.json?i=${this.bn}`);
  }

  public getInsights(): Observable<InsightB2B[]> {
    return this.http.get<InsightB2B[]>(`./strings/B2B-Insight.json?i=${this.bn}`);
  }

  public getMails(): Observable<MailKeyB2BMail[]> {
    return this.http.get<MailKeyB2BMail[]>(`./strings/B2B-Mailbox.json?i=${this.bn}`);
  }

  public getMenuEntries(): Observable<MenuEntryKeyB2BMenuEntry[]> {
    return this.http.get<MenuEntryKeyB2BMenuEntry[]>(`./strings/B2B-Menu.json?i=${this.bn}`);
  }

  public getStringValues(): Observable<StringKeyB2BStringValue[]> {
    return this.http.get<StringKeyB2BStringValue[]>(`./strings/B2B-StringValue.json?i=${this.bn}`);
  }
}
