import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ShowHideStatus, Team, TeamCreation, TeamModification, TeamsSelection } from 'src/app/generated/model/seminar';
import { ConfigService } from 'src/app/services';

import * as Generated from 'src/app/generated/api/seminar/team-api';
import { Debug } from 'src/app/helpers/debug';
import { TokenStorage } from 'src/app/helpers';

@Injectable({
  providedIn: 'root'
})

export class TeamApi extends Generated.TeamApi {
  private header: HttpHeaders;

  constructor(private debug: Debug,
              private tokenStorage: TokenStorage,
              http: HttpClient,
              configService: ConfigService) {
    super(http, configService);
    const token = this.tokenStorage.getToken();

    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
  }

  public getTeams(): Observable<Team[]> {
    return super.getAllTeams(this.header)
      .pipe(catchError(error => {
        console.error(`getTeams failed`, error);
        this.debug.alert(`getTeams failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public getSelectionTeams(
    teamsSelection: TeamsSelection,
  ): Observable<Team[]> {
    return super.postGetSelectionTeams(teamsSelection, this.header);
  }

  public override getDeletedTeams(): Observable<Team[]> {
    return super.getDeletedTeams(this.header)
      .pipe(catchError(error => {
        console.error(`getDeletedTeams failed`, error);
        this.debug.alert(`getDeletedTeams failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public override getChallengeTeams(challengeId: number): Observable<Team[]> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId was equal to 0 when calling TeamApi.getChallengeTeams.');
    }
    return super.getChallengeTeams(challengeId, this.header)
      .pipe(catchError(error => {
        console.error(`getChallengeTeams ${challengeId} failed`, error);
        this.debug.alert(`getChallengeTeams ${challengeId} failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public override getTeam(teamId: string): Observable<Team> {
    return super.getTeam(teamId, this.header)
      .pipe(catchError(error => {
        console.error(`getTeam ${teamId} failed`, error);
        this.debug.alert(`getTeam ${teamId} failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public getShowHideStatus(teamId: string): Observable<ShowHideStatus> {
    return super.getStatusShowHideStatus(teamId, this.header);
  }

  public override postTeam(
    team: TeamCreation,
  ): Observable<Team> {
    return super.postTeam(team, this.header)
      .pipe(catchError(error => {
        console.error(`postTeam failed`, error);
        return EMPTY;
      }));
  }

  public postTeams(
    teams: TeamCreation[],
  ): Observable<any> {
    return super.postSilent({ teams }, this.header);
  }

  public override putTeam(
    team: TeamModification,
  ): Observable<Team> {
    return super.putTeam(team, this.header)
      .pipe(catchError(error => {
        console.error(`putTeam failed`, error);
        return EMPTY;
      }));
  }

  public override putRenameTeam(
    team: TeamModification,
    teamId: string
  ): Observable<Team> {
    return super.putRenameTeam(team, teamId, this.header)
      .pipe(catchError(error => {
        console.error(`putRenameTeam failed`, error);
        return EMPTY;
      }));
  }

  public swapFirm(
    team: TeamModification,
    teamId: string,
  ): Observable<Team> {
    return super.putFirmTeam(team, teamId, this.header)
      .pipe(catchError(error => {
        console.error(`swapFirm failed`, error);
        return EMPTY;
      }));
  }

  public override delete(challengeId: number, teamId: string): Observable<any> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId was equal to 0 when calling TeamApi.delete.');
    }
    return super.delete(challengeId, teamId, this.header);
  }

  public deleteTeams(
    teamsSelection: TeamsSelection
  ): Observable<any> {
    return super.postDeleteTeams(teamsSelection, this.header);
  }

  public override deleteMarkedAsDeleted(): Observable<any> {
    return super.deleteMarkedAsDeleted(this.header);
  }

  public sendMail(teamId: string): Observable<number> {
    return super.postSendMail({ teamId }, this.header)
      .pipe(catchError(error => {
        console.error(`sendMail failed`, error);
        return EMPTY;
      }));
  }

  public sendMails(
    teamsSelection: TeamsSelection
  ): Observable<number> {
    return super.postSendMailTeams(teamsSelection, this.header)
      .pipe(catchError(error => {
        console.error(`postSendMailTeams failed`, error);
        return EMPTY;
      }));
  }

  public clearCache(): Observable<any> {
    return super.postClearcache( this.header);
  }

  public cleanUnused(): Observable<any> {
    return super.postCleanUnused( this.header);
  }
}
