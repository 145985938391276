import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  static readonly userSessionId: string = UserSessionService.uuid();

  constructor() {}

  private static uuid(): string {
    // Private array of chars to use
    const CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');

    // eslint-disable-next-line one-var
    const chars = CHARS, uuid = [], rnd = Math.random;

    // rfc4122, version 4 form
    let r: number;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        // eslint-disable-next-line no-bitwise
        r = 0 | rnd() * 16;
        // eslint-disable-next-line eqeqeq, no-bitwise
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r & 0xf];
      }
    }

    return uuid.join('');
  }

  public getUserSessionId(): string {
    return UserSessionService.userSessionId.toLowerCase();
  }

}
