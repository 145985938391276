import { Observable, Subject } from "rxjs";
import { Challenge, Team } from "../generated/model/seminar";
import { InjectionToken } from "@angular/core";

export const ID_SERVICE_TOKEN = new InjectionToken<IIdService>('ID_SERVICE_TOKEN');

export interface IIdService {
  get challenge(): Challenge | undefined;
  get initialized$(): Subject<boolean>;
  get updated$(): Subject<Challenge>;
  get team(): Team | undefined;
  get teamId(): string | undefined;
  get errordeleted$(): Subject<boolean>;
  get error404$(): Subject<boolean>;

  initializeTeam(teamId: string): void;
  initializeChallenge(challengeId: number): void;
  updateChallenge(): Observable<Challenge | null>;
}
