/**
 * AccountApi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by CentrX/CentrXBackend/InterfaceGenerator/ApiGenerator.
 * Do not edit the class manually.
 */
/* eslint-disable max-len */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/services';
import { UserAccount, UserAccountCreated, UserRole, UsersSelection } from '../../model/auth';

@Injectable({
  providedIn: 'root'
})
export class AccountApi {
  private defaultHeaders = new HttpHeaders();
  private baseUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
    const { authBaseUrl } = this.configService.config;
    this.baseUrl = authBaseUrl;
  }

  protected getPriviledgeUserAccounts(headers: HttpHeaders | null = null): Observable<UserAccount[]> {
    const url = `${this.baseUrl}/api/Account/priviledge`;
    return this.http.get<UserAccount[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getAccountsUserAccounts(headers: HttpHeaders | null = null): Observable<UserAccount[]> {
    const url = `${this.baseUrl}/api/Account/accounts`;
    return this.http.get<UserAccount[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getExistsLogin(
    login: string,
    headers: HttpHeaders | null = null
  ): Observable<boolean> {

    if (!login) {
      throw new Error('Required parameter login was null or undefined when calling AccountApi.getExistsLogin.');
    }
    const url = `${this.baseUrl}/api/Account/exists/login/${login}`;
    return this.http.get<boolean>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getExistsEmail(
    email: string,
    headers: HttpHeaders | null = null
  ): Observable<boolean> {

    if (!email) {
      throw new Error('Required parameter email was null or undefined when calling AccountApi.getExistsEmail.');
    }
    const url = `${this.baseUrl}/api/Account/exists/email/${email}`;
    return this.http.get<boolean>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getExistsName(
    name: string,
    headers: HttpHeaders | null = null
  ): Observable<boolean> {

    if (!name) {
      throw new Error('Required parameter name was null or undefined when calling AccountApi.getExistsName.');
    }
    const url = `${this.baseUrl}/api/Account/exists/name/${name}`;
    return this.http.get<boolean>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getFacilitatorsUserAccounts(headers: HttpHeaders | null = null): Observable<UserAccount[]> {
    const url = `${this.baseUrl}/api/Account/facilitators`;
    return this.http.get<UserAccount[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getFacilitatorUserAccount(
    uuid: string,
    headers: HttpHeaders | null = null
  ): Observable<UserAccount> {

    if (!uuid) {
      throw new Error('Required parameter uuid was null or undefined when calling AccountApi.getFacilitatorUserAccount.');
    }
    const url = `${this.baseUrl}/api/Account/facilitator/${uuid}`;
    return this.http.get<UserAccount>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getMeUserRole(headers: HttpHeaders | null = null): Observable<UserRole> {
    const url = `${this.baseUrl}/api/Account/me`;
    return this.http.get<UserRole>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected postConnectUserRole(
    connectId: string,
    headers: HttpHeaders | null = null
  ): Observable<UserRole> {

    if (!connectId) {
      throw new Error('Required parameter connectId was null or undefined when calling AccountApi.postConnectUserRole.');
    }
    const url = `${this.baseUrl}/api/Account/connect`;
    return this.http.post<UserRole>(url, `"${connectId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
     });
  }

  protected getAccount(
    uuid: string,
    headers: HttpHeaders | null = null
  ): Observable<string> {

    if (!uuid) {
      throw new Error('Required parameter uuid was null or undefined when calling AccountApi.getAccount.');
    }
    const url = `${this.baseUrl}/api/Account/account/${uuid}`;
    return this.http.get<string>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected postGetSeminarUsersUserAccounts(
    usersSelection: UsersSelection,
    headers: HttpHeaders | null = null
  ): Observable<UserAccount[]> {
    const url = `${this.baseUrl}/api/Account/getSeminarUsers`;
    return this.http.post<UserAccount[]>(url, usersSelection, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected postCreateUserUserAccountCreated(
    createUserId: string,
    headers: HttpHeaders | null = null
  ): Observable<UserAccountCreated> {

    if (!createUserId) {
      throw new Error('Required parameter createUserId was null or undefined when calling AccountApi.postCreateUserUserAccountCreated.');
    }
    const url = `${this.baseUrl}/api/Account/createUser`;
    return this.http.post<UserAccountCreated>(url, `"${createUserId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
     });
  }

  protected putUpdateUserAccountCreated(
    updateId: string,
    headers: HttpHeaders | null = null
  ): Observable<UserAccountCreated> {

    if (!updateId) {
      throw new Error('Required parameter updateId was null or undefined when calling AccountApi.putUpdateUserAccountCreated.');
    }
    const url = `${this.baseUrl}/api/Account/update`;
    return this.http.put<UserAccountCreated>(url, `"${updateId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
     });
  }

  protected delete(
    uuid: string,
    headers: HttpHeaders | null = null
  ): Observable<any> {

    if (!uuid) {
      throw new Error('Required parameter uuid was null or undefined when calling AccountApi.delete.');
    }
    const url = `${this.baseUrl}/api/Account/${uuid}`;
    return this.http.delete(url, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`delete ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postDeleteUsers(
    usersSelection: UsersSelection,
    headers: HttpHeaders | null = null
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Account/delete/users`;
    return this.http.post(url, usersSelection, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected deleteForever(
    uuid: string,
    headers: HttpHeaders | null = null
  ): Observable<any> {

    if (!uuid) {
      throw new Error('Required parameter uuid was null or undefined when calling AccountApi.deleteForever.');
    }
    const url = `${this.baseUrl}/api/Account/forever/${uuid}`;
    return this.http.delete(url, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`delete ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postDeleteForeverUsers(
    usersSelection: UsersSelection,
    headers: HttpHeaders | null = null
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Account/delete/forever/users`;
    return this.http.post(url, usersSelection, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postRestore(
    restoreId: string,
    headers: HttpHeaders | null = null
  ): Observable<any> {

    if (!restoreId) {
      throw new Error('Required parameter restoreId was null or undefined when calling AccountApi.postRestore.');
    }
    const url = `${this.baseUrl}/api/Account/restore`;
    return this.http.post(url, `"${restoreId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postUnlockUserAccount(
    unlockId: string,
    headers: HttpHeaders | null = null
  ): Observable<UserAccount> {

    if (!unlockId) {
      throw new Error('Required parameter unlockId was null or undefined when calling AccountApi.postUnlockUserAccount.');
    }
    const url = `${this.baseUrl}/api/Account/unlock`;
    return this.http.post<UserAccount>(url, `"${unlockId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
     });
  }

  protected postLockUserAccount(
    lockId: string,
    headers: HttpHeaders | null = null
  ): Observable<UserAccount> {

    if (!lockId) {
      throw new Error('Required parameter lockId was null or undefined when calling AccountApi.postLockUserAccount.');
    }
    const url = `${this.baseUrl}/api/Account/lock`;
    return this.http.post<UserAccount>(url, `"${lockId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
     });
  }

  protected postClearDate(
    clearDateId: string,
    headers: HttpHeaders | null = null
  ): Observable<any> {

    if (!clearDateId) {
      throw new Error('Required parameter clearDateId was null or undefined when calling AccountApi.postClearDate.');
    }
    const url = `${this.baseUrl}/api/Account/clearDate`;
    return this.http.post(url, `"${clearDateId}"`, {
      headers: headers ?? this.defaultHeaders.set('Content-Type', 'application/json')
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }
}
