/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { SeminarService } from './seminar.service';
import { TeamApi } from '../api/seminar/team-api';
import { DictionaryOf } from '../model';

import { Observable, ReplaySubject, Subject, map, of } from 'rxjs';
import { SeminarEvent, SeminarEventInfo, ShowHideStatus, Team, TeamCreation, TeamModification, TeamsSelection } from '../generated/model/seminar';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class TeamCacheService {
  readonly componentName = 'TeamCacheService';
  private _teams: DictionaryOf<Team> = {};
  private _initialized$: Subject<boolean>;
  private _changed$: Subject<SeminarEventInfo | null>;
  private console = LogService.initialize(this.componentName);

  constructor(private teamApi: TeamApi, private seminarService: SeminarService) {
    this._initialized$ = new ReplaySubject<boolean>(1);
    this._changed$ = new ReplaySubject<SeminarEventInfo | null>(1);

    this.teamApi.getTeams().subscribe(teams => {
      this._teams = {};
      teams.forEach(team => {
        this._teams[team?.teamId as string] = team;
      });

      this.console.log(`INITIALIZED`);

      this._initialized$.next(true);
      this._initialized$.complete();
    });

    this.seminarService.teamNotification$.subscribe(x => {
      switch (x.seminarEvent) {
        case SeminarEvent.TeamAdded:
        case SeminarEvent.TeamDeleted:
        case SeminarEvent.TeamEdited:
        case SeminarEvent.TeamRemoved:
        case SeminarEvent.TeamRenamed:
        case SeminarEvent.TeamsSilentOperation:
        case SeminarEvent.TeamMailSent:
          this.reload(x);
          break;
      }
    });

    this.seminarService.challengeNotification$.subscribe(x => {
      switch (x.seminarEvent) {
        case SeminarEvent.ChallengeAdded:
        case SeminarEvent.ChallengeEdited:
        case SeminarEvent.ChallengeDeleted:
        case SeminarEvent.ChallengeRenamed:
        case SeminarEvent.ChallengeMailSent:
        case SeminarEvent.ChallengeSilentOperation:
        case SeminarEvent.ChallengeSelectionDeleted:
          this.reload(x);
          break;
      }
    });
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get changed$(): Subject<SeminarEventInfo | null> {
    return this._changed$;
  }

  public reload(x: (SeminarEventInfo | null)): void {
    this.teamApi.getTeams().subscribe(teams => {
      this._teams = {};
      teams.forEach(team => {
        if (!team?.deletedDate) {
          this._teams[team?.teamId as string] = team;
        }
      });

      this.console.log(`CHANGED`, Object.keys(this._teams));
      this._changed$.next(x);
    });
  }

  public getTeams(): Observable<Team[]> {
    return this._initialized$.pipe(map(x => {
      const teams = Object.values(this._teams);
      this.console.log(`getTeams()`, teams);
      return teams;
    }));
  }

  public getSelectionTeams(
    teamsSelection: TeamsSelection,
  ): Observable<Team[]> {
    return of(Object.values(this._teams).filter(team => teamsSelection.ids?.includes(team?.teamId as string)));
  }

  public getChallengeTeams(challengeId: number): Observable<Team[]> {
    return of(Object.values(this._teams).filter(team => team?.challengeId === challengeId));
  }

  public getTeam(teamId: string): Observable<Team> {
    if (Object.keys(this._teams).includes(`${teamId}`)) {
      const team = this._teams[teamId];
      this.console.log(`getTeam ${teamId}`, team);
      return of(team);
    } else {
      return this.teamApi.getTeam(teamId).pipe(map(team => {
        this._teams[team?.teamId as string] = team;
        this.console.log(`getTeam ${teamId}`, team);
        return team;
      }));
    }
  }

  public getDeletedTeams(): Observable<Team[]> {
    return this.teamApi.getDeletedTeams();
  }

  public getShowHideStatus(teamId: string): Observable<ShowHideStatus> {
    return this.teamApi.getShowHideStatus(teamId);
  }

  public postTeam(
    team: TeamCreation,
  ): Observable<Team> {
    return this.teamApi.postTeam(team);
  }

  public postTeams(
    teams: TeamCreation[],
  ): Observable<any> {
    return this.teamApi.postTeams(teams);
  }

  public putTeam(
    team: TeamModification
  ): Observable<Team> {
    return this.teamApi.putTeam(team);
  }

  public putRenameTeam(
    team: TeamModification,
    teamId: string,
  ): Observable<Team> {
    return this.teamApi.putRenameTeam(team, teamId);
  }

  public swapFirm(
    team: TeamModification,
    teamId: string,
  ): Observable<Team> {
    return this.teamApi.swapFirm(team, teamId);
  }

  public delete(challengeId: number, teamId: string): Observable<any> {
    delete this._teams[teamId];
    return this.teamApi.delete(challengeId, teamId);
  }

  public deleteTeams(
    teamsSelection: TeamsSelection
  ): Observable<any> {

    teamsSelection.ids?.forEach(id => {
      delete this._teams[id];
    });
    return this.teamApi.deleteTeams(teamsSelection);
  }

  public deleteMarkedAsDeleted(): Observable<any> {
    return this.teamApi.deleteMarkedAsDeleted();
  }

  public sendMail(teamId: string): Observable<number> {
    return this.teamApi.sendMail(teamId);
  }

  public sendMails(
    teamsSelection: TeamsSelection
  ): Observable<number> {
    return this.teamApi.sendMails(teamsSelection);
  }
}
