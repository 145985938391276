/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HubService } from './abstract.hub.service';
import { ReplaySubject, Subject } from 'rxjs';
import { TeamPeriodState, TeamPeriodTransition, TeamTransition } from '../generated/model/state';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class TeamStateService extends HubService {
  protected override console = LogService.initialize('TeamStateService');
  private _teamTransition$: Subject<TeamTransition>;
  private _teamPeriodState$: Subject<TeamPeriodState>;

  constructor(configService: ConfigService) {
    super();
    this.console.log(`constructor()`);
    const { stateBaseUrl } = configService.config;
    this._baseUrl = stateBaseUrl;

    this._teamTransition$ = new ReplaySubject<TeamTransition>(1);
    this._teamPeriodState$ = new ReplaySubject<TeamPeriodState>(1);
  }

  public get teamTransition$(): Subject<TeamTransition> {
    return this._teamTransition$;
  }

  public get teamPeriodState$(): Subject<TeamPeriodState> {
    return this._teamPeriodState$;
  }

  public async sendPeriodTransition(periodTransition: TeamPeriodTransition): Promise<boolean> {
    periodTransition.when = new Date();

    this.console.log(`==============================> sendPeriodTransition ${periodTransition.transition} period ${periodTransition.id?.period}`,
      periodTransition);

    if (this.hubConnection) {
      try {
        await this.hubConnection.invoke('TeamTransition', periodTransition);
        return Promise.resolve(true);
      } catch (error) {
        console.error(error);
        window.alert(error); // TODO@NST
      }
    }

    return Promise.resolve(false);
  }

  protected getUrl(teamId: string): string {
    return `${this._baseUrl}/statehub?instanceId=${encodeURIComponent(teamId)}`;
  }

  protected onHubConnectionBuilt(): void {

    if (this.hubConnection) {
      const event: string = 'TeamState';
      this.hubConnection.on(event, (message: TeamPeriodState) => {
        this.console.log(`==============================> hubConnection.on ${event} ${message.state} period ${message.id.period}`, message);
        this._teamPeriodState$.next(message);
      });
    }

    if (this.hubConnection) {
      const event: string = 'TeamTransition';
      this.hubConnection.on(event, (message: TeamTransition) => {
        this.console.log(`==============================> hubConnection.on ${event} ${message}`);
        this._teamTransition$.next(message);
      });
    }
  }
}
