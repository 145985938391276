import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { AuthApi } from '../api/auth/auth-api';
import { Credentials } from '../model/Credentials';
import { TokenStorage } from '../helpers/token-storage';
import { UserToken } from '../generated/model/auth';
import { LogService } from './log.service';
import { CryptoHelper } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends TokenStorage {

  // https://blog.angular-university.io/angular-jwt-authentication/
  // see also
  // google angular authentication
  // https://jasonwatmore.com/post/2019/06/22/angular-8-jwt-authentication-example-tutorial#authentication-service-ts
  // https://www.bezkoder.com/angular-12-jwt-auth/


  roles: string[] = [];

  private _isLoggedIn$: Subject<boolean>;

  private _isLoggedIn: boolean;
  private tokenStorage: AuthService;
  private readonly serviceName = 'AuthService';
  private console = LogService.initialize(this.serviceName);

  constructor(private authApi: AuthApi, cryptoHelper: CryptoHelper) {
    super(cryptoHelper);
    this.console.log(`constructor()`);
    this.tokenStorage = this;
    this._isLoggedIn = !this.isExpired();
    this._isLoggedIn$ = new BehaviorSubject(this._isLoggedIn);
    LogService.activated$.subscribe(_=> this.console = LogService.initialize(this.serviceName));
  }

  public get isLoggedIn$(): Subject<boolean> {
    return this._isLoggedIn$;
  }

  public login(credentials: Credentials): Observable<any> {
    this.console.log(`login()`);

    return this.authApi.login(credentials)
      .pipe(tap(res => this.setSession(res)))
      .pipe(shareReplay());
  }

  public logout(): void {
    this.tokenStorage.signOut();
    this._isLoggedIn = false;
    this._isLoggedIn$.next(false);
  }

  public async checkAuthenticated(): Promise<boolean> {
    this.console.log(`checkAuthenticated()`);

    const userToken: UserToken | null = this.tokenStorage.getUser();
    if (userToken && userToken.expirationDate !== undefined) {
      const now = Date.now();
      const expiration = new Date(userToken.expirationDate).getTime();
      this.console.log(`checkAuthenticated(${now} < ${expiration})`);

      if (now < expiration) {
        return Promise.resolve(true);
      }
    }

    return Promise.resolve(false);
  }

  private setSession(authResult: UserToken): void {
    this.console.log(`authResult`, authResult);

    if (authResult && authResult.jwtToken) {
      this.tokenStorage.saveToken(authResult.jwtToken);
      this.tokenStorage.saveUser(authResult);
      this._isLoggedIn = true;
      this._isLoggedIn$.next(true);
    } else {
      this.tokenStorage.signOut();
      this._isLoggedIn = false;
      this._isLoggedIn$.next(false);
    }
  }

  private isExpired(): boolean {
    const userToken: UserToken | null = this.tokenStorage.getUser();
    if (userToken && userToken.expirationDate !== undefined) {
      const now = Date.now();
      const expiration = new Date(userToken.expirationDate).getTime();
      if (now < expiration) {
        return false;
      }
    }

    return true;
  }
}
