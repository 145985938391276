import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { envName } from './environments/envName';


switch (environment.name) {
  case envName.Production:
  case envName.LocalProd:
    enableProdMode();
    break;
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
