/**
 * ScenarioBioPharmaApi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by CentrX/CentrXBackend/InterfaceGenerator/ApiGenerator.
 * Do not edit the class manually.
 */
/* eslint-disable max-len */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/services';
import { ScenarioDescription, ScenarioInformationBioPharma, StringInformationBioPharma } from '../../model/scenario';

@Injectable({
  providedIn: 'root'
})
export class ScenarioBioPharmaApi {
  private defaultHeaders = new HttpHeaders();
  private baseUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
    const { scenarioBaseUrl } = this.configService.config;
    this.baseUrl = scenarioBaseUrl;
  }

  protected getSpecificStringsStringInformationBioPharma(
    scenarioId: string,
    headers: HttpHeaders | null = null
  ): Observable<StringInformationBioPharma> {

    if (!scenarioId) {
      throw new Error('Required parameter scenarioId was null or undefined when calling ScenarioBioPharmaApi.getSpecificStringsStringInformationBioPharma.');
    }
    const url = `${this.baseUrl}/api/ScenarioBioPharma/specificStrings/${scenarioId}`;
    return this.http.get<StringInformationBioPharma>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getInformationScenarioInformationBioPharma(
    scenarioId: string,
    headers: HttpHeaders | null = null
  ): Observable<ScenarioInformationBioPharma> {

    if (!scenarioId) {
      throw new Error('Required parameter scenarioId was null or undefined when calling ScenarioBioPharmaApi.getInformationScenarioInformationBioPharma.');
    }
    const url = `${this.baseUrl}/api/ScenarioBioPharma/information/${scenarioId}`;
    return this.http.get<ScenarioInformationBioPharma>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getScenariosDescriptionsScenarioDescriptions(headers: HttpHeaders | null = null): Observable<ScenarioDescription[]> {
    const url = `${this.baseUrl}/api/ScenarioBioPharma/scenariosDescriptions`;
    return this.http.get<ScenarioDescription[]>(url, {headers: headers ?? this.defaultHeaders});
  }
}
