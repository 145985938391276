import { Injectable } from '@angular/core';
import { CryptoHelper } from './crypto-helper';
import { UserToken } from '../generated/model/auth';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorage {

  constructor(private cryptoHelper: CryptoHelper) {}

  // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public getUser(): UserToken | null {
    let encrypted = window.localStorage.getItem(USER_KEY);

    if (encrypted?.includes('displayName')) {
      const userKey = encrypted;
      this.saveUser( JSON.parse(userKey));

      encrypted = window.localStorage.getItem(USER_KEY);
    }

    if (encrypted) {
      const decrypt = this.cryptoHelper.simpleDecrypt(encrypted);
      return  JSON.parse(decrypt);
    }

    return null;
  }

  protected signOut(): void {
    // window.localStorage.clear();
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
  }

  protected saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  protected saveUser(user: UserToken): void {
    window.localStorage.removeItem(USER_KEY);

    const encrypted = this.cryptoHelper.simpleEncrypt( JSON.stringify(user));

    window.localStorage.setItem(USER_KEY, encrypted);
  }

  protected clearUser(): void {
    window.localStorage.removeItem(USER_KEY);
  }
}
