import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountEventInfo } from 'src/app/generated/model/auth';
import { ConfigService, HubService, LogService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class IdentityService extends HubService  {
  readonly componentName = 'IdentityService';
  protected override console = LogService.initialize(this.componentName);
  private _accountNotification$: Subject<AccountEventInfo>;

  constructor(configService: ConfigService) {
    super();
    // this.console.log(`constructor()`);
    const { authBaseUrl } = configService.config;
    this._baseUrl = authBaseUrl;
    this._accountNotification$ = new Subject<AccountEventInfo>();
   }

  public get accountNotification$(): Subject<AccountEventInfo> {
    return this._accountNotification$;
  }

  protected getUrl(instanceId: string): string {
    return  `${this._baseUrl}/identityhub?instanceId=${instanceId}`;
  }

  protected onHubConnectionBuilt(): void {
    if (this.hubConnection) {
      const event: string = 'Account';
      this.hubConnection.on(event, message => {
        this.console.log('hubConnection.on', event, message);
        this._accountNotification$.next(message);
      });
    }
  }
}
