import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  // TODO@NST https://indepth.dev/posts/1465/expecting-the-unexpected-best-practices-for-error-handling-in-angular-2
  constructor(private zone: NgZone) { }
  handleError(error: any): void {

    if (error instanceof TypeError) {
      console.error(`GlobalErrorHandler`, error.stack);
      this.zone.run(() =>
        window.alert(error.stack)
      );
    } else
      if (error instanceof HttpErrorResponse) {
        console.error(`GlobalErrorHandler ${error.status}: ${error.message}`, error);
        this.zone.run(() => {
          if (error.message.toLowerCase().endsWith('/upload: 0 unknown error')) {
            // TODO@NST Find a better way to cach this
            // Catch specific file upload error (previous error message is useless)
            // Used e.g for Excel course file upload in Dashboard
            window.alert('Error while uploading file.\nIs the file opened in an editor? If so, please close it and retry.');
          } else {
            window.alert(`Error: ${error.message}`);
          }
        });
      } else {
        console.error(`GlobalErrorHandler`, error);
        this.zone.run(() =>
          window.alert(error)
        );
      }
  }
}
