/**
 * CentrXSeminar.Api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* eslint-disable max-len */


export type SeminarEvent = 'None' | 'UserDeleted' | 'UserAdded' | 'UserRemoved' | 'UserMoved' | 'UserEdited' | 'UserMailSent' | 'TeamDeleted' | 'TeamAdded' | 'TeamRemoved' | 'TeamRenamed' | 'TeamEdited' | 'TeamMailSent' | 'ChallengeDeleted' | 'ChallengeAdded' | 'ChallengeEdited' | 'ChallengeRenamed' | 'ChallengeMailSent' | 'InstructorDeleted' | 'InstructorAdded' | 'InstructorRemoved' | 'InstructorEdited' | 'InstructorAddedSilent' | 'ToolUsersAdded' | 'ToolUsersUpdated' | 'ToolUsersDeleted' | 'UserSelectionDeleted' | 'InstructorSelectionDeleted' | 'TeamSelectionDeleted' | 'ChallengeSelectionDeleted' | 'UserSelectionMailSent' | 'TeamSelectionMailSent' | 'ChallengeSilentOperation' | 'TeamsSilentOperation' | 'TeamHidden' | 'ChallengeHidden' | 'TeamShown' | 'ChallengeShown' | 'MaintenanceModeOn' | 'MaintenanceModeOff' | 'CountdownEdited' | 'Error';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SeminarEvent = {
    None: 'None' as SeminarEvent,
    UserDeleted: 'UserDeleted' as SeminarEvent,
    UserAdded: 'UserAdded' as SeminarEvent,
    UserRemoved: 'UserRemoved' as SeminarEvent,
    UserMoved: 'UserMoved' as SeminarEvent,
    UserEdited: 'UserEdited' as SeminarEvent,
    UserMailSent: 'UserMailSent' as SeminarEvent,
    TeamDeleted: 'TeamDeleted' as SeminarEvent,
    TeamAdded: 'TeamAdded' as SeminarEvent,
    TeamRemoved: 'TeamRemoved' as SeminarEvent,
    TeamRenamed: 'TeamRenamed' as SeminarEvent,
    TeamEdited: 'TeamEdited' as SeminarEvent,
    TeamMailSent: 'TeamMailSent' as SeminarEvent,
    ChallengeDeleted: 'ChallengeDeleted' as SeminarEvent,
    ChallengeAdded: 'ChallengeAdded' as SeminarEvent,
    ChallengeEdited: 'ChallengeEdited' as SeminarEvent,
    ChallengeRenamed: 'ChallengeRenamed' as SeminarEvent,
    ChallengeMailSent: 'ChallengeMailSent' as SeminarEvent,
    InstructorDeleted: 'InstructorDeleted' as SeminarEvent,
    InstructorAdded: 'InstructorAdded' as SeminarEvent,
    InstructorRemoved: 'InstructorRemoved' as SeminarEvent,
    InstructorEdited: 'InstructorEdited' as SeminarEvent,
    InstructorAddedSilent: 'InstructorAddedSilent' as SeminarEvent,
    ToolUsersAdded: 'ToolUsersAdded' as SeminarEvent,
    ToolUsersUpdated: 'ToolUsersUpdated' as SeminarEvent,
    ToolUsersDeleted: 'ToolUsersDeleted' as SeminarEvent,
    UserSelectionDeleted: 'UserSelectionDeleted' as SeminarEvent,
    InstructorSelectionDeleted: 'InstructorSelectionDeleted' as SeminarEvent,
    TeamSelectionDeleted: 'TeamSelectionDeleted' as SeminarEvent,
    ChallengeSelectionDeleted: 'ChallengeSelectionDeleted' as SeminarEvent,
    UserSelectionMailSent: 'UserSelectionMailSent' as SeminarEvent,
    TeamSelectionMailSent: 'TeamSelectionMailSent' as SeminarEvent,
    ChallengeSilentOperation: 'ChallengeSilentOperation' as SeminarEvent,
    TeamsSilentOperation: 'TeamsSilentOperation' as SeminarEvent,
    TeamHidden: 'TeamHidden' as SeminarEvent,
    ChallengeHidden: 'ChallengeHidden' as SeminarEvent,
    TeamShown: 'TeamShown' as SeminarEvent,
    ChallengeShown: 'ChallengeShown' as SeminarEvent,
    MaintenanceModeOn: 'MaintenanceModeOn' as SeminarEvent,
    MaintenanceModeOff: 'MaintenanceModeOff' as SeminarEvent,
    CountdownEdited: 'CountdownEdited' as SeminarEvent,
    Error: 'Error' as SeminarEvent
};