import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ReplaySubject, Subject } from 'rxjs';
import { LogService } from './log.service';
import { environment } from 'src/environments/environment';

export abstract class HubService {

  protected hubConnection: HubConnection | null;
  protected _baseUrl: string = '';

  protected serviceName = 'HubService';

  protected console = LogService.initialize(this.serviceName);

  private _connectionIsEstablished: boolean = false;
  private _connectionOnGoing: boolean = false;

  private _addr: string = '';
  private _connectionEstablished$: Subject<boolean>;
  private _initialized$: Subject<boolean>;

  private instanceId: string = '';

  private disconnectionOnGoing: boolean = false;
  private retry: number = 0;
  private connectionRetryTimeout!: NodeJS.Timeout | null;


  constructor() {
    this.console.log(`constructor()`);
    this.hubConnection = null;
    this._initialized$ = new ReplaySubject(1);
    this._connectionEstablished$ = new ReplaySubject(1);
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get addr(): string {
    return this._addr;
  }

  public get baseUrl(): string {
    return this._baseUrl;
  }

  public get connectionEstablished$(): Subject<boolean> {
    return this._connectionEstablished$;
  }

  public get connectionIsEstablished(): boolean {
    return this._connectionIsEstablished;
  }

  public get connectionOnGoing(): boolean {
    return this._connectionOnGoing;
  }

  public connect(instanceId: string): void {
    this.instanceId = instanceId;
    this.console.log(`connect ${instanceId}`); // `

    if (!this._connectionIsEstablished && !this._connectionOnGoing) {
      this._connectionOnGoing = true;

      this.createConnection(instanceId);
      this.startConnection();
    }
  }

  public disconnect(): void {
    this.console.log(`disconnect ${this.instanceId}`);

    this.stopConnectionRetry();
    this.disconnectionOnGoing = true;

    if (this.hubConnection) {
      this.hubConnection.stop().then(() => {
        this._connectionIsEstablished = false;
        this.connectionEstablished$.next(this._connectionIsEstablished);
      }
      ).finally(() => {
        this.disconnectionOnGoing = false;
      });
    }
  }

  private createConnection(instanceId: string): void {
    this._addr = this.getUrl(instanceId);

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.getUrl(instanceId))
      // .withAutomaticReconnect()
      .configureLogging(environment.loglevel)
      .build();

    this.onHubConnectionBuilt();

    this.hubConnection.onclose(() => {
      this.console.log(`connection closed for challenge ${instanceId} disconnectionOnGoing=${this.disconnectionOnGoing}`);
      this._connectionIsEstablished = false;
      this.connectionEstablished$.next(this._connectionIsEstablished);

      if (!this.disconnectionOnGoing) {
        this.console.log('Connection lost ... Reconnecting');
        setTimeout(() => { this.startConnection(); }, 15000);
      }
    });

    this.console.log(`INITIALIZED`);
    this._initialized$.next(true);
    this._initialized$.complete();
  }

  private startConnection(): void {

    this.stopConnectionRetry();
    this._connectionOnGoing = true;

    if (this.hubConnection) {
      this.hubConnection
        .start()
        .then(() => {
          this.console.log(`connection started for team ${this.instanceId}`);
          this._connectionIsEstablished = true;
          this._connectionOnGoing = false;
          this.connectionEstablished$.next(this._connectionIsEstablished);
        })
        .catch(err => {
          this.retry++;
          console.warn(`Error while establishing connection for challenge ${this.instanceId}, retrying (${this.retry}) ...`);
          this.connectionRetryTimeout = setTimeout(() => { this.startConnection(); }, 30000);
        });
    }
  }

  private stopConnectionRetry(): void {
    if (this.connectionRetryTimeout) {
      clearTimeout(this.connectionRetryTimeout);
      this.connectionRetryTimeout = null;
      this._connectionOnGoing = false;
    }
  }

  protected abstract getUrl(instanceId: string): string;
  protected abstract onHubConnectionBuilt(): void;
}
