/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
import { LogLevel } from '@microsoft/signalr';
import { envName } from './envName';

export const environment = {
  name: envName.Production,
  loglevel: LogLevel.Warning,
  version: require('../../package.json').version,
  buildNumber: "20240917.2",
  formatBuildNumber(buildNumber: string): string {
    const bnStr = (Number(buildNumber?.replace(/\D/g, '') ?? '0')
      + Number(buildNumber?.replace(/\D/g, '')?.split('')?.reverse()?.join('') ?? '0')).toString();
    return bnStr.substring(0, Math.round(bnStr.length / 2));
  }
};
