/**
 * ChallengeApi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by CentrX/CentrXBackend/InterfaceGenerator/ApiGenerator.
 * Do not edit the class manually.
 */
/* eslint-disable max-len */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/services';
import { Challenge, ChallengeCreation, ChallengeModification, ChallengeSelection, ChallengesSelection, ShowHideStatus } from '../../model/seminar';

@Injectable({
  providedIn: 'root'
})
export class ChallengeApi {
  private defaultHeaders = new HttpHeaders();
  private baseUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
    const { seminarBaseUrl } = this.configService.config;
    this.baseUrl = seminarBaseUrl;
  }

  protected getAllChallenges(headers: HttpHeaders | null = null): Observable<Challenge[]> {
    const url = `${this.baseUrl}/api/Challenge/all`;
    return this.http.get<Challenge[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getDeletedChallenges(headers: HttpHeaders | null = null): Observable<Challenge[]> {
    const url = `${this.baseUrl}/api/Challenge/deleted`;
    return this.http.get<Challenge[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getChallenge(
    challengeId: number,
    headers: HttpHeaders | null = null
  ): Observable<Challenge> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling ChallengeApi.getChallenge.');
    }
    const url = `${this.baseUrl}/api/Challenge/${challengeId}`;
    return this.http.get<Challenge>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected putChallenge(
    challengeModification: ChallengeModification,
    challengeId: number,
    headers: HttpHeaders | null = null
  ): Observable<Challenge> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling ChallengeApi.putChallenge.');
    }
    const url = `${this.baseUrl}/api/Challenge/${challengeId}`;
    return this.http.put<Challenge>(url, challengeModification, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected delete(
    challengeId: number,
    headers: HttpHeaders | null = null
  ): Observable<any> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling ChallengeApi.delete.');
    }
    const url = `${this.baseUrl}/api/Challenge/${challengeId}`;
    return this.http.delete(url, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`delete ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected getStatusShowHideStatus(
    challengeId: number,
    headers: HttpHeaders | null = null
  ): Observable<ShowHideStatus> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling ChallengeApi.getStatusShowHideStatus.');
    }
    const url = `${this.baseUrl}/api/Challenge/status/${challengeId}`;
    return this.http.get<ShowHideStatus>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getHidden(headers: HttpHeaders | null = null): Observable<number[]> {
    const url = `${this.baseUrl}/api/Challenge/hidden`;
    return this.http.get<number[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected postChallenge(
    challengeCreation: ChallengeCreation,
    headers: HttpHeaders | null = null
  ): Observable<Challenge> {
    const url = `${this.baseUrl}/api/Challenge`;
    return this.http.post<Challenge>(url, challengeCreation, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected putCreatorChallenge(
    challengeModification: ChallengeModification,
    challengeId: number,
    headers: HttpHeaders | null = null
  ): Observable<Challenge> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling ChallengeApi.putCreatorChallenge.');
    }
    const url = `${this.baseUrl}/api/Challenge/creator/${challengeId}`;
    return this.http.put<Challenge>(url, challengeModification, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected postDeleteChallenges(
    challengesSelection: ChallengesSelection,
    headers: HttpHeaders | null = null
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Challenge/delete/challenges`;
    return this.http.post(url, challengesSelection, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected deleteMarkedAsDeleted(headers: HttpHeaders | null = null): Observable<any> {
    const url = `${this.baseUrl}/api/Challenge/markedAsDeleted`;
    return this.http.delete(url, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`delete ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postSendMail(
    challengeSelection: ChallengeSelection,
    headers: HttpHeaders | null = null
  ): Observable<number> {
    const url = `${this.baseUrl}/api/Challenge/sendMail`;
    return this.http.post<number>(url, challengeSelection, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected postClearcache(headers: HttpHeaders | null = null): Observable<any> {
    const url = `${this.baseUrl}/api/Challenge/clearcache`;
    return this.http.post(url, {}, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }
}
