import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountHelper {
  public static isFormatedEmail(email: string): boolean {
    // eslint-disable-next-line max-len
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
  }

  public static generatePasword(): string {
    const randomstring = Math.random().toString(36).slice(-8);
    return randomstring;
  }
}
