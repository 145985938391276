import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ScenarioDescription, ScenarioInformationBioPharma, StringInformationBioPharma } from 'src/app/generated/model/scenario';
import { ConfigService } from 'src/app/services';

import * as Generated from 'src/app/generated/api/scenario/scenario-bio-pharma-api';
import { TokenStorage } from 'src/app/helpers';


@Injectable({
  providedIn: 'root'
})
export class ScenarioBioApi extends Generated.ScenarioBioPharmaApi {
  private header: HttpHeaders;
  constructor(http: HttpClient,
              configService: ConfigService,
              private tokenStorage: TokenStorage,) {
    super(http, configService);
    const token = this.tokenStorage.getToken();

    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
  }

  public getScenariosDescriptions(): Observable<ScenarioDescription[]> {
    return super.getScenariosDescriptionsScenarioDescriptions(this.header);
  }

  public getScenarioInformation(
    scenarioId: string,
    ): Observable<ScenarioInformationBioPharma> {

    return super.getInformationScenarioInformationBioPharma(scenarioId, this.header);
  }

  public getSpecificStringsInformation(
    scenarioId: string,
   ): Observable<StringInformationBioPharma> {
    return super.getSpecificStringsStringInformationBioPharma(scenarioId, this.header);
  }
}
