import { Injectable } from '@angular/core';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { ScenarioApi } from 'src/app/api/scenario/scenario-api';
import { ScenarioDescription } from 'src/app/generated/model/scenario';
import { DictionaryOf } from 'src/app/model';
import { LogService } from 'src/app/services';
import { ScenarioBioApi } from '../api/scenario/scenario-bio-api';

@Injectable({
  providedIn: 'root'
})
export class DashboardScenarioService {
  readonly componentName = 'DashboardScenarioService';
  private _initialized$: Subject<boolean>;
  private _scenarioB2BDescriptionDict: DictionaryOf<ScenarioDescription> = {};
  private _scenarioB2BDescriptions: ScenarioDescription[] = [];

  private _scenarioBioDescriptionDict: DictionaryOf<ScenarioDescription> = {};
  private _scenarioBioDescriptions: ScenarioDescription[] = [];

  private console = LogService.initialize(this.componentName);

  constructor(private scenarioApi: ScenarioApi, private scenarioApiBio: ScenarioBioApi) {
    this._initialized$ = new ReplaySubject(1);

    combineLatest([
      this.scenarioApi.getScenariosDescriptions(),
      this.scenarioApiBio.getScenariosDescriptions(),

    ])
      .subscribe(([scenarioB2BDescriptions, scenarioBioDescriptions]) => {
        this.console.log(`getScenariosDescriptions`, scenarioB2BDescriptions, scenarioBioDescriptions);

        this._scenarioB2BDescriptions = scenarioB2BDescriptions;
        scenarioB2BDescriptions.forEach(x => {
          this._scenarioB2BDescriptionDict[x.id] = x;
        });

        this._scenarioBioDescriptions = scenarioBioDescriptions;
        scenarioBioDescriptions.forEach(x => {
          this._scenarioBioDescriptionDict[x.id] = x;
        });

        this.console.log(`INITIALIZED`);
        this._initialized$.next(true);
        this._initialized$.complete();
      });
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  public get scenarioB2BDescriptionDict(): DictionaryOf<ScenarioDescription> {
    return this._scenarioB2BDescriptionDict;
  }

  public get scenarioB2BDescriptions(): ScenarioDescription[] {
    return this._scenarioB2BDescriptions;
  }

  public get scenarioBioDescriptionDict(): DictionaryOf<ScenarioDescription> {
    return this._scenarioBioDescriptionDict;
  }

  public get scenarioBioDescriptions(): ScenarioDescription[] {
    return this._scenarioBioDescriptions;
  }
}
