import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Csharp {

  public static get MAX_SAFE_INTEGER(): number { return Math.pow(2, 31) - 1; }

  /**
   * @returns the round value as implemented in Csharp
   */
  public static round(value: number): number {
    const minusHalf: number = value - 0.5;
    const floor: number = Math.floor(value);
    const even: boolean =  (floor % 2 === 0);

    if (minusHalf === floor && even) {
      return floor;
    } else {
      return Math.round(value);
    }
  }
}
