/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ChallengeApi } from '../api/seminar/challenge-api';
import { Challenge, ChallengeCreation, ChallengeModification, ChallengesSelection, SeminarEvent, SeminarEventInfo, ShowHideStatus, SimulationType } from '../generated/model/seminar';
import { DictionaryOf } from '../model';
import { Observable, ReplaySubject, Subject, map, of } from 'rxjs';
import { SeminarService } from './seminar.service';
import { LogService } from './log.service';
import { DashboardScenarioService } from './scenario.service';

@Injectable({
  providedIn: 'root'
})
export class ChallengeCacheService {
  readonly componentName = 'ChallengeCacheService';
  private _challenges: DictionaryOf<Challenge> = {};
  private _initialized$: Subject<boolean>;
  private _changed$: Subject<SeminarEventInfo | null>;
  private console = LogService.initialize(this.componentName);

  constructor(
    private challengeApi: ChallengeApi,
    private seminarService: SeminarService,
    private scenarioService: DashboardScenarioService
  ) {
    this._initialized$ = new ReplaySubject<boolean>(1);
    this._changed$ = new ReplaySubject<SeminarEventInfo | null>(1);

    this.scenarioService.initialized$.subscribe(_ => {
      this.challengeApi.getChallenges().subscribe(challenges => {
        this._challenges = {};
        challenges.forEach(challenge => {
          this._challenges[challenge?.challengeId] = this.consolidateChallenge(challenge);
        });
        this.console.log(`INITIALIZED`);
        this._initialized$.next(true);
        this._initialized$.complete();
      });

      this.seminarService.challengeNotification$.subscribe(x => {

        switch (x.seminarEvent) {
          case SeminarEvent.ChallengeAdded:
          case SeminarEvent.ChallengeEdited:
          case SeminarEvent.ChallengeDeleted:
          case SeminarEvent.ChallengeRenamed:
          case SeminarEvent.ChallengeMailSent:
          case SeminarEvent.ChallengeSilentOperation:
          case SeminarEvent.ChallengeSelectionDeleted:
            this.reload(x);
        }
      });
    });
  }

  public get changed$(): Subject<SeminarEventInfo | null> {
    return this._changed$;
  }

  public reload(x: SeminarEventInfo | null): void {
    this.challengeApi.getChallenges().subscribe(challenges => {
      this._challenges = {};
      challenges.forEach(challenge => {
        this._challenges[challenge?.challengeId] = this.consolidateChallenge(challenge);
      });

      this.console.log(`CHANGED`, Object.keys(this._challenges));
      this._changed$.next(x);
    });
  }

  public getChallenges(): Observable<Challenge[]> {
    return this._initialized$.pipe(map(x => {
      const challenges = Object.values(this._challenges);
      this.console.log(`getChallenges()`, challenges);
      return challenges;
    }));
  }

  public getChallenge(challengeId: number): Observable<Challenge> {
    if (Object.keys(this._challenges).includes(`${challengeId}`)) {
      const challenge = this._challenges[challengeId];
      this.console.log(`getChallenge ${challengeId}`, challenge);
      return of(challenge);
    } else {
      return this.challengeApi.getChallenge(challengeId).pipe(map(challenge => {
        this._challenges[challenge?.challengeId] = challenge;
        this.console.log(`getChallenge ${challengeId}`, challenge);
        return challenge;
      }));
    }
  }

  public delete(challengeId: number): Observable<any> {
    delete this._challenges[challengeId];
    this.console.log(`delete ${challengeId}`, Object.keys(this._challenges));
    return this.challengeApi.delete(challengeId);
  }

  public deleteChallenges(
    challengesSelection: ChallengesSelection,
  ): Observable<any> {

    challengesSelection.ids?.forEach(id => {
      delete this._challenges[id];
    });
    return this.challengeApi.deleteChallenges(challengesSelection);
  }

  public putCreator(
    challengeId: number,
    creator: string,
  ): Observable<Challenge> {
    return this.challengeApi.putCreator(challengeId, creator);
  }

  public putChallenge(
    challenge: ChallengeModification,
    challengeId: number,
  ): Observable<Challenge> {
    return this.challengeApi.putChallenge(challenge, challengeId);
  }

  public postChallenge(
    challenge: ChallengeCreation,
  ): Observable<Challenge> {
    return this.challengeApi.postChallenge(challenge);
  }

  public getDeletedChallenges(): Observable<Challenge[]> {
    return this.challengeApi.getDeletedChallenges();
  }

  public sendMail(challengeId: number): Observable<number> {
    return this.challengeApi.sendMail(challengeId);
  }

  public deleteMarkedAsDeleted(): Observable<any> {
    return this.challengeApi.deleteMarkedAsDeleted();
  }

  public getShowHideStatus(challengeId: number): Observable<ShowHideStatus> {
    return this.challengeApi.getShowHideStatus(challengeId);
  }

  public getHidden(): Observable<number[]> {
    return this.challengeApi.getHidden();
  }

  private consolidateChallenge(challenge: Challenge): Challenge {
    if (challenge) {
      challenge.simulation = SimulationType[this.scenarioService.scenarioB2BDescriptionDict[challenge.scenarioId].type as keyof typeof SimulationType];
    }
    return challenge;
  }
}
