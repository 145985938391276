/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AuthApi } from '../api/auth/auth-api';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeService {
  private _initialized$: Subject<boolean>;
  private serverTime: Date | undefined;
  private localTime: Date;
  private diff: number = 0;

  constructor(private authApi: AuthApi) {
    this._initialized$ = new ReplaySubject<boolean>(1);
    this.localTime = new Date();

    this.authApi.getUtcNow().subscribe(utcDate => {
      const localTime = new Date();
      this.serverTime = new Date(`${utcDate}`);
      // this._serverTime = new Date('Fri, 13 Apr 2023 11:23:22 GMT');
      this._initialized$.next(true);
      this._initialized$.complete();

      const requestTime = localTime.getTime() - this.localTime.getTime();
      this.diff = this.localTime.getTime() - this.serverTime.getTime();
      console.warn(`Local: ${this.localTime.toUTCString()} Server: ${this.serverTime.toUTCString()} Corrected: ${ this.utcTime} Request:${requestTime}ms Difference:${this.diff}ms`);
    });
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  get utcTime(): Date | undefined {
    const serverTime = new Date(new Date().getTime() - this.diff);
    return serverTime;
  }
}
