<div class="splash-screen fade-in" *ngIf="reason===DisplayReason.Maintenance || DisplayReason.Error503">
  <div class="maintenance" *ngIf="reason===DisplayReason.Maintenance">
    <mat-icon>engineering</mat-icon>
    <div class="caption">
      Site under maintenance
    </div>
    <div class="subcaption">Please come back later!</div>
  </div>

  <div class="maintenance" *ngIf="reason===DisplayReason.Error503">
    <mat-icon>electrical_services</mat-icon>
    <div class="caption">
      ERR_CONNECTION_REFUSED
    </div>
    <div class="subcaption">The page is temporarily unavailable</div>
  </div>

  <div class="logout-button-container" *ngIf="reason===DisplayReason.Error503">
    <div class="span-container">
      <span>
        <button title="Logout" class="LogoutButton round-button" mat-icon-button
          [disabled]="(authenticated$ | async) === false" (click)="logoutButtonClicked()">
          <mat-icon *ngIf="(authenticated$ | async) === false">
            person_outline
          </mat-icon>
          <mat-icon *ngIf="authenticated$ | async">
            person
          </mat-icon>
        </button>
      </span>
    </div>
  </div>
</div>

<div id="watermark" *ngIf="watermark && canSeeLinks">
  <p>
    <ng-container *ngFor="let n of numbers">{{watermark}} </ng-container>
  </p>
</div>

<router-outlet></router-outlet>

<footer>
  <div class="BrandArea">
    <div class="app-version">
      <a href="http://www.{{companyDomain}}.com" target="_blank">
        <img alt="{{companyName}} logo" src="assets/logo-company-regular-black.svg">
      </a> - version {{version}}
    </div>
    <div class="links">
      <ng-container *ngIf="canSeeLinks">
        {{configType}}
        &nbsp;<a href="{{decisionAddress}}/version" target="_blank">Decision</a>
        &nbsp;<a href="{{engineAddress}}/version" target="_blank">Engine</a>
        &nbsp;<a href="{{identityAddress}}/version" target="_blank">Identity</a>
        &nbsp;<a href="{{portfolioAddress}}/version" target="_blank">Portfolio</a>
        &nbsp;<a href="{{scenarioAddress}}/version" target="_blank">Scenario</a>
        &nbsp;<a href="{{seminarAddress}}/version" target="_blank">Seminar</a>
        &nbsp;<a href="{{stateAddress}}/version" target="_blank">State</a>
        &nbsp;
      </ng-container>
      &nbsp;<div>Build Number: {{buildNumber}}</div>
    </div>

  </div>
</footer>
