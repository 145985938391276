import { Injectable } from '@angular/core';
import { ChallengePeriodState, ChallengePeriodTransition, ChallengeTransition } from '../generated/model/state';
import { ConfigService } from './config.service';
import { HubService } from './abstract.hub.service';
import { ReplaySubject, Subject } from 'rxjs';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ChallengeStateService extends HubService  {
  readonly componentName = 'ChallengeStateService';
  protected override console = LogService.initialize(this.componentName);
  private _challengeTransition$: Subject<ChallengeTransition>;
  private _challengePeriodState$: Subject<ChallengePeriodState>;

  constructor(configService: ConfigService) {
    super();
    // this.console.log(`constructor()`);
    const { stateBaseUrl } = configService.config;
    this._baseUrl = stateBaseUrl;

    this._challengeTransition$ = new ReplaySubject<ChallengeTransition>(1);
    this._challengePeriodState$ = new ReplaySubject<ChallengePeriodState>(1);
  }

  public get challengeTransition$(): Subject<ChallengeTransition> {
    return this._challengeTransition$;
  }

  public get challengePeriodState$(): Subject<ChallengePeriodState> {
    return this._challengePeriodState$;
  }

  public async sendPeriodTransition(periodTransition: ChallengePeriodTransition): Promise<boolean> {

    periodTransition.when = new Date();

    this.console.log(`sendPeriodTransition()`, periodTransition);

    if (this.hubConnection) {
      // this.hubConnection.invoke('Message', JSON.stringify(periodTransition));

      try {
        await this.hubConnection.invoke('ChallengeTransition', periodTransition);
        return Promise.resolve(true);
      } catch (error) {
        this.console.log(error);
        window.alert(error); // TODO@NST
      }
    }

    return Promise.resolve(false);
  }

  protected getUrl(challengeId: string): string {
    return  `${this._baseUrl}/statehub?instanceId=${encodeURIComponent(challengeId)}`;
  }

  protected onHubConnectionBuilt(): void {

    if (this.hubConnection) {
      const event: string = 'ChallengeState';
      this.hubConnection.on(event, message => {
        this.console.log('hubConnection.on', event, message);
        this._challengePeriodState$.next(message);
      });
    }

    if (this.hubConnection) {
      const event: string = 'ChallengeTransition';
      this.hubConnection.on(event, message => {
        this.console.log('hubConnection.on', event, message);
        this._challengeTransition$.next(message);
      });
    }
  }
}
