import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { envName } from 'src/environments/envName';
import { Config } from 'src/environments/config';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private static _config: Config;

  constructor(private http: HttpClient) {}

  public get config(): Config {
    return ConfigService._config;
  }
  async loadConfig(): Promise<Config> {

    let addr = './environments/configuration.prod.json';

    switch (environment.name) {
      case envName.LocalProd:
        addr = './environments/configuration.localprod.json';
        break;
      case envName.Development:
        addr = './environments/configuration.json';
        break;
    }

    // const data = await this.http.get<Config>(addr).toPromise<Config>();
    const data = await lastValueFrom(this.http.get<Config>(addr));
    return ConfigService._config = data as Config;
  }
}
