// eslint-disable-next-line max-len
export type DisplayReason = 'None' | 'NotStarted' | 'Loading' | 'TeamDeleted' | 'Error404' | 'Error503' | 'Forbidden' | 'HideSim' | 'Maintenance';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DisplayReason = {
  None: 'None' as DisplayReason,
  NotStarted: 'NotStarted' as DisplayReason,
  Loading: 'Loading' as DisplayReason,
  TeamDeleted: 'TeamDeleted' as DisplayReason,
  Error404: 'Error404' as DisplayReason,
  Error503: 'Error503' as DisplayReason,
  Forbidden: 'Forbidden' as DisplayReason,
  HideSim: 'HideSim' as DisplayReason,
  Maintenance: 'Maintenance' as DisplayReason,
};
