import { Injectable } from '@angular/core';
import { AuthService, LogService, UserSessionService } from 'src/app/services';
import { ConnectedUser } from '../generated/model/decision';
import { UserRole, UserToken } from '../generated/model/auth';

@Injectable({
  providedIn: 'root'
})
export class ConnectedUserService {
  private _user: ConnectedUser | undefined;
  private _userSessionId: string;
  private console = LogService.initialize('ConnectedUserService');

  constructor(private userSessionService: UserSessionService,
    private tokenStorage: AuthService) {
    this.console.log(`constructor()`);

    this._userSessionId = this.userSessionService.getUserSessionId();

    const userToken: UserToken | null = this.tokenStorage.getUser();

    if (userToken) {
      this.console.log(`userToken`, userToken);
      this._user = {
        login: userToken.login,
        displayName: userToken.displayName,
        uuid: userToken.uuid,
        connectionId: this._userSessionId
      };
    }

    this.console.log(``, this._user);
  }

  public get user(): ConnectedUser | undefined {
    return this._user;
  }

  public get userSessionId(): string {
    return this._userSessionId;
  }

  public getUserCopy(): ConnectedUser {
    return { login: this._user?.login, displayName: this._user?.displayName, uuid: this._user?.uuid };
  }

  public update(user: UserRole): ConnectedUser {
    (this._user as (ConnectedUser)).login = user.login;
    (this._user as (ConnectedUser)).displayName = user.displayName;
    return this._user as (ConnectedUser);
  }
}
