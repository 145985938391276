/**
 * TeamApi
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by CentrX/CentrXBackend/InterfaceGenerator/ApiGenerator.
 * Do not edit the class manually.
 */
/* eslint-disable max-len */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService } from 'src/app/services';
import { ShowHideStatus, Team, TeamCreation, TeamModification, TeamsCreation, TeamSelection, TeamsSelection } from '../../model/seminar';

@Injectable({
  providedIn: 'root'
})
export class TeamApi {
  private defaultHeaders = new HttpHeaders();
  private baseUrl: string;

  constructor(private http: HttpClient,
              private configService: ConfigService) {
    const { seminarBaseUrl } = this.configService.config;
    this.baseUrl = seminarBaseUrl;
  }

  protected getAllTeams(headers: HttpHeaders | null = null): Observable<Team[]> {
    const url = `${this.baseUrl}/api/Team/All`;
    return this.http.get<Team[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected postGetSelectionTeams(
    teamsSelection: TeamsSelection,
    headers: HttpHeaders | null = null
  ): Observable<Team[]> {
    const url = `${this.baseUrl}/api/Team/getSelection`;
    return this.http.post<Team[]>(url, teamsSelection, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected getDeletedTeams(headers: HttpHeaders | null = null): Observable<Team[]> {
    const url = `${this.baseUrl}/api/Team/deleted`;
    return this.http.get<Team[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getChallengeTeams(
    challengeId: number,
    headers: HttpHeaders | null = null
  ): Observable<Team[]> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling TeamApi.getChallengeTeams.');
    }
    const url = `${this.baseUrl}/api/Team/challenge/${challengeId}`;
    return this.http.get<Team[]>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getTeam(
    teamId: string,
    headers: HttpHeaders | null = null
  ): Observable<Team> {

    if (!teamId) {
      throw new Error('Required parameter teamId was null or undefined when calling TeamApi.getTeam.');
    }
    const url = `${this.baseUrl}/api/Team/${teamId}`;
    return this.http.get<Team>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected getStatusShowHideStatus(
    teamId: string,
    headers: HttpHeaders | null = null
  ): Observable<ShowHideStatus> {

    if (!teamId) {
      throw new Error('Required parameter teamId was null or undefined when calling TeamApi.getStatusShowHideStatus.');
    }
    const url = `${this.baseUrl}/api/Team/status/${teamId}`;
    return this.http.get<ShowHideStatus>(url, {headers: headers ?? this.defaultHeaders});
  }

  protected postTeam(
    teamCreation: TeamCreation,
    headers: HttpHeaders | null = null
  ): Observable<Team> {
    const url = `${this.baseUrl}/api/Team`;
    return this.http.post<Team>(url, teamCreation, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected putTeam(
    teamModification: TeamModification,
    headers: HttpHeaders | null = null
  ): Observable<Team> {
    const url = `${this.baseUrl}/api/Team`;
    return this.http.put<Team>(url, teamModification, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected postSilent(
    teamsCreation: TeamsCreation,
    headers: HttpHeaders | null = null
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Team/silent`;
    return this.http.post(url, teamsCreation, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected putRenameTeam(
    teamModification: TeamModification,
    teamId: string,
    headers: HttpHeaders | null = null
  ): Observable<Team> {

    if (!teamId) {
      throw new Error('Required parameter teamId was null or undefined when calling TeamApi.putRenameTeam.');
    }
    const url = `${this.baseUrl}/api/Team/rename/${teamId}`;
    return this.http.put<Team>(url, teamModification, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected putFirmTeam(
    teamModification: TeamModification,
    teamId: string,
    headers: HttpHeaders | null = null
  ): Observable<Team> {

    if (!teamId) {
      throw new Error('Required parameter teamId was null or undefined when calling TeamApi.putFirmTeam.');
    }
    const url = `${this.baseUrl}/api/Team/firm/${teamId}`;
    return this.http.put<Team>(url, teamModification, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected delete(
    challengeId: number,
    teamId: string,
    headers: HttpHeaders | null = null
  ): Observable<any> {

    if (challengeId===null || challengeId===undefined) {
      throw new Error('Required parameter challengeId was null or undefined when calling TeamApi.delete.');
    }

    if (!teamId) {
      throw new Error('Required parameter teamId was null or undefined when calling TeamApi.delete.');
    }
    const url = `${this.baseUrl}/api/Team/${teamId}/challenge/${challengeId}`;
    return this.http.delete(url, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`delete ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postDeleteTeams(
    teamsSelection: TeamsSelection,
    headers: HttpHeaders | null = null
  ): Observable<any> {
    const url = `${this.baseUrl}/api/Team/delete/teams`;
    return this.http.post(url, teamsSelection, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected deleteMarkedAsDeleted(headers: HttpHeaders | null = null): Observable<any> {
    const url = `${this.baseUrl}/api/Team/markedAsDeleted`;
    return this.http.delete(url, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`delete ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postSendMail(
    teamSelection: TeamSelection,
    headers: HttpHeaders | null = null
  ): Observable<number> {
    const url = `${this.baseUrl}/api/Team/sendMail`;
    return this.http.post<number>(url, teamSelection, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected postSendMailTeams(
    teamsSelection: TeamsSelection,
    headers: HttpHeaders | null = null
  ): Observable<number> {
    const url = `${this.baseUrl}/api/Team/sendMail/teams`;
    return this.http.post<number>(url, teamsSelection, {
      headers: headers ?? this.defaultHeaders
     });
  }

  protected postClearcache(headers: HttpHeaders | null = null): Observable<any> {
    const url = `${this.baseUrl}/api/Team/clearcache`;
    return this.http.post(url, {}, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }

  protected postCleanUnused(headers: HttpHeaders | null = null): Observable<any> {
    const url = `${this.baseUrl}/api/Team/cleanUnused`;
    return this.http.post(url, {}, {
      headers: headers ?? this.defaultHeaders
    }).pipe(catchError(error => {
      console.error(`post ${url} failed`, error);
      return EMPTY;
    }));
  }
}
