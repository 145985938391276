import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { BrowserModule } from '@angular/platform-browser';
// import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import { AppRoutingModule } from './app-routing.module';
import { ConfigService } from './services';
import { AppComponent } from './app.component';

import { BUILD_NUMBER } from './variable';
import { environment } from 'src/environments/environment';
import { GlobalErrorHandler } from './helpers/global-error-handler';
import { MatButtonModule } from '@angular/material/button';

import 'src/extensions/string.extensions';

// import { B2bSimulationModule } from './modules/simulation/b2b/simulation.module';
// import { B2bDebrieferModule } from './modules/debriefer/b2b/debriefer.module';
// import { DashboardModule } from './modules/dashboard/dashboard.module';
// import { PortalModule } from './modules/portal/portal.module';
// import { WidgetsModule } from './widgets/widgets.module';
// import { StateModule } from './modules/state/state.module';
// import { LoginModule } from './modules/login/login.module';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configFactory = (configService: ConfigService) =>
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  () => configService.loadConfig()
  ;

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,

    // BrowserModule,
    // FormsModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,

    // *** REMOVED FOR LAZY LOADING **
    // B2bSimulationModule,
    // B2bDebrieferModule,
    // DashboardModule,
    // PortalModule,
    // StateModule,
    // LoginModule,
    // WidgetsModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: configFactory, deps: [ConfigService], multi: true },
    { provide: BUILD_NUMBER, useValue: environment.buildNumber },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
