import { Injectable } from '@angular/core';

import CryptoES from 'crypto-es';

import { ConfigService } from '../services';
import { WordArray } from 'crypto-es/lib/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoHelper {
  private key: string;

  constructor(private configService: ConfigService) {
    const { authKey: publicKey } = this.configService.config;
    this.key = publicKey;
  }

  public asymetricEncrypt(msg: string): string {
    const keySize = 256;
    const salt = CryptoES.lib.WordArray.random(16);
    const key = CryptoES.PBKDF2(this.key, salt, {
      keySize: keySize / 32,
      iterations: 100
    });

    const iv: CryptoES.lib.WordArray = CryptoES.lib.WordArray.random(128 / 8);
    const encrypted = CryptoES.AES.encrypt(msg, key, {
      iv,
      padding: CryptoES.pad.Pkcs7,
      mode: CryptoES.mode.CBC
    });

    const ciphertext = encrypted.ciphertext as WordArray;

    return CryptoES.enc.Base64.stringify(salt.concat(iv).concat(ciphertext));
  }

  public symetricEncrypt(msg: string): string {
    const key = CryptoES.enc.Utf8.parse(this.key.substring(0, 16));
    const iv = CryptoES.lib.WordArray.create([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const encrypted = CryptoES.AES.encrypt(msg, key, {
      iv,
      padding: CryptoES.pad.Pkcs7,
      mode: CryptoES.mode.CBC
    });

    const ciphertext = encrypted.ciphertext as WordArray;

    return ciphertext.toString(CryptoES.enc.Base64);
  }

  public symetricDecrypt(ciphertextB64: string): string {
    const key = CryptoES.enc.Utf8.parse(this.key.substring(0, 16));
    const iv = CryptoES.lib.WordArray.create([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const decrypted = CryptoES.AES.decrypt(ciphertextB64, key, {
      iv,
      padding: CryptoES.pad.Pkcs7,
      mode: CryptoES.mode.CBC
    });

    return decrypted.toString(CryptoES.enc.Utf8);
  }

  public simpleEncrypt(msg: string): string {
    return CryptoES.AES.encrypt( msg.trim(), this.key).toString();
  }

  public simpleDecrypt(encryptedMessage: string): string {
    return CryptoES.AES.decrypt( encryptedMessage,  this.key ).toString(CryptoES.enc.Utf8);
  }
}
