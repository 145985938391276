import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Challenge, ChallengeCreation, ChallengeModification, ChallengesSelection, ShowHideStatus } from 'src/app/generated/model/seminar';
import { ConfigService, LogService } from 'src/app/services';

import * as Generated from 'src/app/generated/api/seminar/challenge-api';
import { Debug } from 'src/app/helpers/debug';
import { TokenStorage } from 'src/app/helpers';

@Injectable({
  providedIn: 'root'
})
export class ChallengeApi extends Generated.ChallengeApi {
  readonly componentName = 'ChallengeApi';
  private console = LogService.initialize(this.componentName);
  private header: HttpHeaders;

  constructor(private debug: Debug,
              private tokenStorage: TokenStorage,
              http: HttpClient,
              configService: ConfigService) {
    super(http, configService);
    const token = this.tokenStorage.getToken();

    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    });
  }

  public getChallenges(): Observable<Challenge[]> {
    return super.getAllChallenges(this.header)
      .pipe(catchError(error => {
        console.error(`getChallenges failed`, error);
        this.debug.alert(`getChallenges failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public override getDeletedChallenges(): Observable<Challenge[]> {
    return super.getDeletedChallenges(this.header)
      .pipe(catchError(error => {
        console.error(`getDeletedChallenges failed`, error);
        this.debug.alert(`getDeletedChallenges failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public override getChallenge(challengeId: number): Observable<Challenge> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId equal to 0 when calling ChallengeApi.getChallenge.');
    }
    return super.getChallenge(challengeId, this.header)
      .pipe(catchError(error => {
        console.error(`getChallenge ${challengeId} failed`, error);
        this.debug.alert(`getChallenge ${challengeId} failed:  ${error.message}\n${error.error}`);
        return EMPTY;
      }));
  }

  public getShowHideStatus(challengeId: number): Observable<ShowHideStatus> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId equal to 0 when calling ChallengeApi.getShowHideStatus.');
    }
    return super.getStatusShowHideStatus(challengeId, this.header);
  }

  public override getHidden(): Observable<number[]> {
    return super.getHidden(this.header);
  }

  public override postChallenge(
    challenge: ChallengeCreation
  ): Observable<Challenge> {
    return super.postChallenge(challenge, this.header)
      .pipe(catchError(error => {
        console.error(`postChallenge failed`, error);
        return EMPTY;
      }));
  }

  public override putChallenge(
    challenge: ChallengeModification,
    challengeId: number,
  ): Observable<Challenge> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId was equal to 0 when calling ChallengeApi.putChallenge.');
    }
    return super.putChallenge(challenge, challengeId, this.header)
      .pipe(catchError(error => {
        console.error(`putChallenge failed`, error);
        return EMPTY;
      }));
  }

  public putCreator(
    challengeId: number,
    creator: string,
  ): Observable<Challenge> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId was equal to 0 when calling ChallengeApi.putCreator.');
    }
    return super.putCreatorChallenge({ challengeId, creator }, challengeId, this.header)
      .pipe(catchError(error => {
        console.error(`putCreator failed`, error);
        return EMPTY;
      }));
  }

  public override delete(challengeId: number): Observable<any> {
    if (challengeId===0) {
      throw new Error('Required parameter challengeId equal to 0 when calling ChallengeApi.delete.');
    }
    return super.delete(challengeId, this.header);
  }

  public deleteChallenges(
    challengesSelection: ChallengesSelection,
  ): Observable<any> {
    return super.postDeleteChallenges(challengesSelection, this.header);
  }

  public override deleteMarkedAsDeleted(): Observable<any> {
    return super.deleteMarkedAsDeleted(this.header);
  }

  public sendMail(
    challengeId: number
  ): Observable<number> {
    return super.postSendMail({ challengeId }, this.header)
    .pipe(catchError(error => {
      console.error(`postSendMail failed`, error);
      return EMPTY;
    }));
  }

  public clearCache(): Observable<any> {
    return super.postClearcache( this.header);
  }
}
