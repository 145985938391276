import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, MaybeAsync, Resolve, RouterStateSnapshot } from '@angular/router';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { B2BStringApi } from 'src/app/api/string/b2b-string-api';
// eslint-disable-next-line max-len
import { InitiativePrefixesB2BInitiativeKeyB2BInitiative, InsightB2B, MailKeyB2BMail, MenuEntryKeyB2BMenuEntry, StringKeyB2BStringValue } from 'src/app/generated/model/scenario';
import { IStringService } from 'src/app/interface';
import { DictionaryOf } from 'src/app/model';

@Injectable({
  providedIn: 'root'
})
export class B2BStringService implements IStringService, Resolve<boolean> {
  private _initialized$: Subject<boolean>;
  private _strings: DictionaryOf<string> = {};
  private _initiatives: DictionaryOf<InitiativePrefixesB2BInitiativeKeyB2BInitiative> = {};
  private _menuEntries: DictionaryOf<MenuEntryKeyB2BMenuEntry> = {};
  private _insights: DictionaryOf<InsightB2B> = {};

  private _stringValues: StringKeyB2BStringValue[] = [];
  private _initiativeValues: InitiativePrefixesB2BInitiativeKeyB2BInitiative[] = [];
  private _insightValues: InsightB2B[] = [];
  private _mails: MailKeyB2BMail[] = [];
  private _menuEntryValues: MenuEntryKeyB2BMenuEntry[] = [];

  constructor(private stringApi: B2BStringApi) {
    console.log(`B2BStringService.constructor()`);
    this._initialized$ = new ReplaySubject<boolean>(1);

    combineLatest([
      this.stringApi.getStringValues(),
      this.stringApi.getInitiatives(),
      this.stringApi.getInsights(),
      this.stringApi.getMails(),
      this.stringApi.getMenuEntries()]).subscribe(([stringValues, initiative, insight, mails, menuEntries]) => {
        this._stringValues = stringValues;

        this._stringValues.forEach(s => {
          if (s && s.name && s.value) {
            this._strings[s.name] = s.value;
          }
        });

        this._initiativeValues = initiative;
        this._initiativeValues.forEach(s => {
          if (s && s.key) {
            this._initiatives[s.key] = s;
          }
        });

        this._insightValues = insight;
        this._insightValues.forEach(s => {
          if (s && s.key) {
            this._insights[s.key] = s;
          }
        });

        this._mails = mails.map(p => ({
          key: p.key,
          content: p.content?.replaceCompanyAndBrandName(),
          sender: p.sender?.replaceCompanyAndBrandName(),
          subject: p.subject?.replaceCompanyAndBrandName(),
        }));

        const replacedMenuEntries = this.replaceFieldsMenuEntries(menuEntries);

        this._menuEntryValues = replacedMenuEntries;
        this.flatten(replacedMenuEntries);

        console.log(`B2BStringService INITIALIZED`);
        this._initialized$.next(true);
      });
  }

  public get strings(): DictionaryOf<string> {
    return this._strings;
  }

  public get mails(): MailKeyB2BMail[] {
    return this._mails;
  }

  public get initiatives(): DictionaryOf<InitiativePrefixesB2BInitiativeKeyB2BInitiative> {
    return this._initiatives;
  }

  public get insights(): DictionaryOf<InsightB2B> {
    return this._insights;
  }

  public get menuEntries(): DictionaryOf<MenuEntryKeyB2BMenuEntry> {
    return this._menuEntries;
  }

  public get initialized$(): Subject<boolean> {
    return this._initialized$;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<boolean> {
    return this._initialized$;
  }

  private flatten(menuEntries: MenuEntryKeyB2BMenuEntry[]): void {

    menuEntries.forEach(entry => {
      if (entry) {
        if (entry.key && entry.value) {
          this._menuEntries[entry.key] = entry;
        }
        if (entry.subMenuEntries) {
          this.flatten(entry.subMenuEntries);
        }
      }
    });
  }

  private replaceFieldsMenuEntries(input: MenuEntryKeyB2BMenuEntry[]): MenuEntryKeyB2BMenuEntry[] {
    return input.map(p => ({
      key: p.key,
      longTitle: p.longTitle?.replaceCompanyAndBrandName(),
      shortTitle: p.shortTitle?.replaceCompanyAndBrandName(),
      prefix: p.prefix?.replaceCompanyAndBrandName(),
      tooltip: p.tooltip?.replaceCompanyAndBrandName(),
      value: p.value?.replaceCompanyAndBrandName(),
      subMenuEntries: (p.subMenuEntries ? this.replaceFieldsMenuEntries(p.subMenuEntries)
        : p.subMenuEntries) as Array<MenuEntryKeyB2BMenuEntry>,
    })) as MenuEntryKeyB2BMenuEntry[];
  }
}
