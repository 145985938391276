import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigService, LogService } from 'src/app/services';

import * as Generated from 'src/app/generated/api/auth/auth-api';
import { CryptoHelper } from 'src/app/helpers';
import { Credentials } from 'src/app/model';

@Injectable({
  providedIn: 'root'
})
export class AuthApi extends Generated.AuthApi {
  private console = LogService.initialize('AuthApi');

  constructor(http: HttpClient,
              configService: ConfigService,
              private cryptoHelper: CryptoHelper) {
    super(http, configService);
  }

  public login(
    credentials: Credentials,
  ): Observable<any> {
    // this.console.log(`login(${encryptCredentials})`);
    const encryptCredentials: string = this.cryptoHelper.asymetricEncrypt(JSON.stringify(credentials));

    return super.postLoginUserToken(encryptCredentials)
      .pipe(catchError(err => {
        if ([401, 403].includes(err.status)) {
          this.console.warn(`login failed`, err.error.title);
          return of(null);
        } else {
          this.console.error(`getLogin failed`, err);
          return EMPTY;
        }
      }));
  }

  public getUtcNow(): Observable<string> {
    const header = new HttpHeaders ({
      'Content-Type': 'application/json',
      responseType: 'text'
    });

    return super.postUtcNow(header)
     .pipe(catchError(err => {
      this.console.error(`getUtcNow failed`, err);
      return EMPTY;
    }));
  }
}
